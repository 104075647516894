import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Shop from '@/views/shop.vue'
import Cloud3a from '@/views/cloud3a.vue'
import GameFi from '@/views/gameFi.vue'

import Layout from '@/layout'

const constantRoutes = [
  {
    path: '/login',
    component: Layout,
    redirect: '/login',
    meta: { 
      requireAuth: false
    },
    children: [{
      path: '/login',
      name: 'login',
      component: () => import('@/views/login'),
      meta: { title: '登录' }
    }]
  },
  {
    path: '/createWallet',
    component: Layout,
    redirect: '/createWallet',
    meta: { 
      requireAuth: false
    },
    children: [{
      path: '/createWallet',
      name: 'createWallet',
      component: () => import('@/views/createWallet'),
      meta: { title: '创建钱包' }
    }]
  },
  {
    path: '/openWallet',
    component: Layout,
    redirect: '/openWallet',
    meta: { 
      requireAuth: false
    },
    children: [{
      path: '/openWallet',
      name: 'openWallet',
      component: () => import('@/views/openWallet'),
      meta: { title: '打开钱包' }
    }]
  },
  {
    path: '/index',
    component: Layout,
    redirect: '/index',
    meta: { 
      requireAuth: true
    },
    children: [{
      path: '/index',
      name: 'index',
      component: () => import('@/views/index'),
      meta: { title: '个人中心' }
    }]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/view',
    meta: { 
      requireAuth: false
    },
    children: [{
      path: '/view',
      name: 'view',
      component: () => import('@/views/teamview'),
      meta: { title: '远程协助' }
    }]
  },
  {
    path: '/setting',
    component: Layout,
    redirect: '/setting',
    meta: { 
      requireAuth: false
    },
    children: [{
      path: '/setting',
      name: 'setting',
      component: () => import('@/views/setting1'),
      meta: { title: '设置' }
    }]
  },
  {
    path: '/shop',
    component: Layout,
    redirect: '/shop',
    meta: { 
      requireAuth: true
    },
    children: [{
      path: '/shop',
      name: 'shop',
      component: () => import('@/views/shop'),
      meta: { title: '商店' }
    }]
  },
  {
    path: '/cloud3a',
    component: Layout,
    redirect: '/cloud3a',
    meta: { 
      requireAuth: true
    },
    children: [{
      path: '/cloud3a',
      name: 'cloud3a',
      component: () => import('@/views/cloud3a'),
      meta: { title: 'CLoud3A' }
    }]
  },
  {
    path: '/gameFi',
    component: Layout,
    redirect: '/gameFi',
    meta: { 
      requireAuth: true
    },
    children: [{
      path: '/gameFi',
      name: 'gameFi',
      component: () => import('@/views/gameFi'),
      meta: { title: 'CLoudGameFi' }
    }]
  },
  {
    path: '/deviceList',
    component: Layout,
    redirect: '/deviceList',
    meta: { 
      requireAuth: true
    },
    children: [{
      path: '/deviceList',
      name: 'deviceList',
      component: () => import('@/views/deviceList'),
      meta: { title: '设备列表' }
    }]
  },
  {
    path: '/gameShare',
    component: Layout,
    redirect: '/gameShare',
    meta: { 
      requireAuth: true
    },
    children: [{
      path: '/gameShare',
      name: 'gameShare',
      component: () => import('@/views/gameShare'),
      meta: { title: '游戏共享 ' }
    }]
  },
  {
    path: '/cybercafe',
    component: Layout,
    redirect: '/cybercafe',
    meta: { 
      requireAuth: true
    },
    children: [{
      path: '/cybercafe',
      name: 'cybercafe',
      component: () => import('@/views/cybercafe'),
      meta: { title: '云网吧' }
    }]
  }
]

const routes = [
  {
    path: '/shop',
    name: 'shop',
    component: Shop
  },
  {
    path: '/cloud3a',
    name: 'cloud3a',
    component: Cloud3a
  },
  {
    path: '/gameFi',
    name: 'gameFi',
    component: GameFi
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: constantRoutes
})

router.beforeEach((to, from, next) => {
  // 需要权限
  if (to.meta.requireAuth) {
    // 判断当前是否拥有权限
    const pair = localStorage.getItem('pair')
    if (pair) {
      next();
    } else {
      // 无权，跳转登录
      next({
        path: 'login'
      })
    }
  } else {
    // 无需登录，直接访问
    next();
  }
})

export default router
