export default {
  public: {
    copysuccess: 'Sao chép thành công',
    days: 'Ngày',
    minimize: 'Thu nhỏ',
    closure: 'Đóng',
    linkFail: 'Máy chủ bị ngắt kết nối',
    linkSuccess: 'Sẵn sàng kết nối (kết nối an toàn)',
    inputPwd: 'Nhập mật khẩu',
    pwdError: 'Sai mật khẩu',
    seedError: 'Lỗi định dạng khóa cá nhân',
    unlock: 'Mở khóa',
    inputSeed: 'Dán hoặc nhập khóa cá nhân',
    walletbalance: 'Số dư ví',
    balance: 'Số dư',
    equivalent: 'Tương đương',
    price: 'Giá',
    dollar: 'USD',
    add: 'Thêm',
    edit: 'Chỉnh sửa',
    save: 'Lưu',
    cancel: 'Hủy',
    hour: 'Giờ',
    min: 'Phút',
    second: 'Giấy',
    codeError: 'Chưa tải mã định danh',
    updateError: 'Cập nhật không thành công',
    customError: 'Tùy chỉnh không thành công',
    tokenError: 'Không thể tạo Token, vui lòng nhấp lại vào nút trang',
    netError: 'Kết nối máy chủ không thành công, vui lòng đợi',

    copy: 'Sao chép',
    introDBC: 'Tìm hiểu DBC',
    loginErr: 'Đăng nhập ví',
    msgErrInfo1: 'Chưa khởi tạo',
    msgErrInfo1000: 'Chưa khởi tạo',
    msgErrInfo1001: 'Đã khởi tạo',
    msgErrInfo1002: 'Lỗi tham số',
    msgErrInfo1003: 'Chưa truyền phát',
    msgErrInfo1004: 'Đang truyền phát',
    msgErrInfo1005: 'Lỗi FD',
    msgErrInfo1006: 'Kết nối máy chủ không thành công',
    msgErrInfo1007: 'license lỗi hoặc hết hạn',
    msgErrInfo1008: 'Lỗi nội bộ',
    msgErrInfo1009: 'Xác thực không thành công',
    msgErrInfo1010: 'Hết thời gian xác thực',
    msgErrInfo1011: 'Không thể tạo luồng video',
    msgErrInfo1012: 'Kết xuất không thành công',
    msgErrInfo1013: 'Kết nối web không thành công',
    msgErrInfo1014: 'Hết thời gian tín hiệu kết nối',
    msgErrInfo1015: 'Hết thời gian kết nối',
    msgErrInfo1016: 'Chuyển tiếp không thành công',
    msgErrInfo1017: 'Không màn hình hiển thị hoặc không thể tạo màn hình ảo',
    msgErrInfo1018: 'Thiết bị đang ngoại tuyến',

    comfirm: 'Xác nhận'
  },
  meau: {
    nav1: 'Hỗ trợ từ xa',
    nav2: 'Cài đặt',
    nav3: 'Danh sách thiết bị',
    nav4: 'Ví của tôi',
    login: 'Mời đăng nhập',
    user1: 'Người dùng phổ thông',
    user2: 'Crown NFT (Phiên bản Chuyên nghiệp)',
    user3: 'Crown NFT (Phiên bản Doanh nghiệp)',
    time: 'Thời hạn hiệu lực còn lại',
    devices: 'Số lượng thiết bị',
  },
  wallet: {
    newWallet: 'Ví mới',
    openWallet: 'Mở ví',
    createWallet: 'Tạo ví',
    tips1: 'Bắt buộc nhớ mật khẩu! Không để mất mật khẩu, bạn cần mật khẩu này và tệp khóa cá nhân để mở khóa ví',
    tips2: 'Lưu trữ tệp khóa cá nhân của bạn, lưu giữ tệp ở nơi an toàn. Ví dụ: ghi chép lại và cất vào két sắt',
    seed: 'Khóa cá nhân của bạn',
    seed1: 'Khóa cá nhân',
    keystore: 'Tệp Keystore',
    tips3: 'Không được để mất, một khi mất sẽ không thể lấy lại',
    tips4: 'Cũng không được chia sẻ! Không gửi thông tin qua lại trên mạng xã hội như WeChat, QQ, Facebook, Line, KakaoTalk, WhatsApp và các ứng dụng khác. Nếu bạn sử dụng tệp này trên web lừa đảo, tiền của bạn sẽ bị đánh cắp!',
    tips5: 'Hãy chắc chắn bạn đã có bản sao lưu! Cứ xem như một ngày nào đó nó sẽ đáng giá hàng triệu đô nhé',
    create: 'Tạo',
    finish: 'Hoàn thành',
    selectFile: 'Chọn tệp ví',
    instructions: 'Ví - Hướng dẫn',
    desc1: 'Sau khi đăng nhập ví, bạn có thể mua Crown NFT phiên bản Chuyên nghiệp, hỗ trợ nhiều tính năng nâng cao',
    details: 'Xem chi tiết',
    desc2: 'Sau khi đăng nhập ví, bạn có thể mua DeepBrainChain Coin và DeepLink Coin',
    desc3: 'Sau khi đăng nhập ví, bạn có thể mua trò chơi hoặc các dịch vụ khác',
    desc4: 'Sau khi đăng nhập ví, bạn có thể chia sẻ trò chơi trong máy với những người chơi khác để thu về DeepBrainChain Coin và DeepLink Coin',
  },
  home: {
    tip: 'Vui lòng tự bảo quản khóa cá nhân. Nếu khóa cá nhân bị mất, sẽ không ai có thể giúp bạn lấy lại tài sản kỹ thuật số của bạn!',
    address: 'Địa chỉ ví',
    transfer: 'Chuyển khoản',
    buy: 'Mua',
    exchange: 'Chuyển đổi',
    switch: 'Thay đổi ví',
    hash: 'Lịch sử giao dịch Hash',
    source: 'Địa chỉ gốc',
    target: 'Địa chỉ đích',
    result: 'Kết quả',
    number: 'Số lượng',

    currency: 'Tiền tệ',
    password: 'Mật khẩu',
    msg: 'Không thể để trống',
    msg1: 'Phải là một loại số',
    msg2: 'chuyển thành công',
    msg3: 'chuyển không thành công',
  },
  view: {
    err: 'Độ dài của mã xác minh là một chuỗi từ sáu đến mười ký tự',
    delect: 'Xóa thành công',
    delecterr: 'Xóa không thành công',
    title1: 'Cho phép Điều khiển từ xa',
    localcode: 'ID máy của bạn',
    localvercode: 'Mật khẩu',
    updatevercode: 'Làm mới',
    customvercode: 'Tùy chỉnh',
    join: 'Tham gia cộng đồng nhận DeepLinkCoin',
    title2: 'Điều khiển thiết bị từ xa',
    remotecode: 'ID máy đối tác',
    remotevercode: 'Mật khẩu',
    singlescreen: 'Mặc định',
    expansionscreen: 'Mở rộng',
    remotenow: 'Kết nối',
    error1: 'Vui lòng nhập đúng mã định danh gồm chín chữ số',
    error2: 'Vui lòng nhập đúng mã xác thực gồm sáu chữ số',
    error3: 'Quá nhiều lỗi, vui lòng thử lại sau 10 phút',
  },
  device: {
    devList: 'Danh sách thiết bị',
    adddev: 'Thêm thiết bị',
    intcafe: 'Quán net đám mây',
    remaining: 'Còn lại',
    renew: 'Gia hạn thuê',
    currentdev: 'Thiết bị hiện tại',
    none: 'Không',
    details: 'Chi tiết đặt hàng',
    identifier: 'Mã định danh',
    vercode: 'Mã xác thực',
    macsystem: 'Hệ điều hành',
    remark: 'Ghi chú',
    countdown: 'Đếm ngược thời gian còn lại',
    local: 'Vị trí',
    rentalTime: 'Tổng thời gian thuê',
    devdetails: 'Chi tiết đặt hàng thiết bị',
    index: 'Số thứ tự',
    leaseStartTime: 'Thời gian bắt đầu thuê',
    rental: 'Thời gian thuê',
    useTime: 'Thời gian sử dụng thực tế',
    rentPaid: 'Đã thanh toán tiền thuê',
    refund: 'Hoàn trả tiền thuê'
  },
  setting: {
    name1: 'Tổng quát',
    name2: 'Bảo mật',
    name3: 'Thêm..',
    rule: {
      title1: 'Bắt đầu và cập nhật',
      label1: 'Tự động khởi động',
      label2: 'Vẫn chịu sự điều khiển từ xa sau khi thoát',
      label3: 'Tự động cập nhật',
      title2: 'Cài đặt phím nóng',
      label4: 'Mở rộng/Cửa sổ',
      label5: 'Thu nhỏ',
      label6: 'Chuyển màn hình',
      title3: 'Ngôn ngữ',
      label7: 'Ngôn ngữ thiết bị',
      error: 'Xung đột phím nóng, cài đặt lại..'
    },
    safe: {
      title1: 'Khóa màn hình',
      label1: 'Tự khóa lúc kết nối',
      label2: 'Tự khóa lúc kết thúc',
      label3: 'Tự khóa lúc đóng màn hình riêng tư',
      title2: 'Cập nhật mật mã',
      label4: 'Không bao giờ',
      label5: 'Sau mỗi lần điều khiển từ xa',
      label6: 'Cập nhật cài đặt'
    },
    other: {
      title1: 'Tắt tiếng thiết bị được điều khiển',
      title2: 'Băng thông(Wan)',
      title3: 'Độ phân giải',
      title4: 'Fps',
      title5: 'Màn hình ảo',
      label1: 'Theo Máy khách',
      label2: 'Theo Máy chủ'
    }
  }
}