import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './locales/i18n'
import VueClipBoard from 'vue-clipboard2'
import { 
  ElDropdown, 
  ElDropdownMenu, 
  ElDropdownItem, 
  ElDialog, 
  ElImage, 
  ElScrollbar, 
  ElMessage, 
  ElTabs,
  ElRate,
  ElTabPane,
  ElCheckbox,
  ElTable,
  ElUpload,
  ElInput,
  ElSelect,
  ElOption,
  ElTooltip,
  ElForm,
  ElFormItem,
  ElProgress,
  ElLoading,
  ElTableColumn,
  ElPagination,
  ElCollapseTransition  } from 'element-plus'
import 'element-plus/dist/index.css'
import { objdoc } from '@/utils/createDoc'
const app = createApp(App);
app.config.globalProperties.$objdoc = objdoc.objects
app.use(i18n)
app.use(router)
app.use(store)
app.use(VueClipBoard)
app.component(ElRate.name, ElRate)
app.component(ElTabs.name, ElTabs)
app.component(ElForm.name, ElForm)
app.component(ElImage.name, ElImage)
app.component(ElTable.name, ElTable)
app.component(ElInput.name, ElInput)
app.component(ElDialog.name, ElDialog)
app.component(ElOption.name, ElOption)
app.component(ElSelect.name, ElSelect)
app.component(ElUpload.name, ElUpload)
app.component(ElTooltip.name, ElTooltip)
app.component(ElTabPane.name, ElTabPane)
app.component(ElProgress.name, ElProgress)
app.component(ElDropdown.name, ElDropdown)
app.component(ElCheckbox.name, ElCheckbox)
app.component(ElFormItem.name, ElFormItem)
app.component(ElScrollbar.name, ElScrollbar)
app.component(ElPagination.name, ElPagination)
app.component(ElTableColumn.name, ElTableColumn)
app.component(ElDropdownMenu.name, ElDropdownMenu)
app.component(ElDropdownItem.name, ElDropdownItem)
app.component(ElCollapseTransition.name, ElCollapseTransition)
app.provide('$message', ElMessage)
app.provide('$loading', ElLoading)
app.mount('#app')


const compare = (val) => {
  return function (a, b) {
    let val1 = a[val]
    let val2 = b[val]
    return val2 - val1
  }
}
window.getQtFun = function(method, data) {
  if (method == 'setDeviceInfo') {
    if (data.history) {
      data.history = data.history.sort(compare('timestamp'))
    }
    store.commit('setLocalData', data)
    store.commit('setOnlineStaus', {state: data.online_state})
  }
  if (method == 'updateOnlineState') {
    store.commit('setOnlineStaus', data)
  }
  if (method == 'preconnect') {
    if (data.code != 0) {
      if (data.code < 100) {
        ElMessage({
          message: data.message,
          type: 'error',
        })
      } else {
        ElMessage({
          message: i18n.global.t(`public.msgErrInfo${data.code}`),
          type: 'error',
        })
      }
    } else {
      // ElMessage({
      //   message: '连接中，请等待...',
      //   type: 'success',
      // })
    }
  }
}

setTimeout(async () => {
  if (!localStorage.getItem('lan')) {
    let data = await objdoc.objects.doc.getSystemLanguages()
    if (data.indexOf('zh') != -1) {
      store.commit('changeLan', 'zh')
    } else if (data.indexOf('en') != -1) {
      store.commit('changeLan', 'en')
    } else if (data.indexOf('ko') != -1) {
      store.commit('changeLan', 'ko')
    } else if (data.indexOf('ja') != -1) {
      store.commit('changeLan', 'ja')
    } else if (data.indexOf('vn') != -1) {
      store.commit('changeLan', 'vn')
    }
  }
}, 2000)