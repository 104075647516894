export default {
  public: {
    copysuccess: '复制成功',
    days: '天',
    minimize: '最小化',
    closure: '关闭',
    linkFail: '连接服务器失败',
    linkSuccess: '已连接服务器(安全加密链路)',
    inputPwd: '请输入密码',
    pwdError: '密码错误',
    seedError: '私钥格式错误~',
    unlock: '解锁',
    inputSeed: '粘贴或者输入私钥',
    walletbalance: '钱包余额',
    balance: '余额',
    equivalent: '等值',
    price: '价格',
    dollar: '美金',
    add: '添加',
    edit: '编辑',
    save: '保存',
    cancal: '取消',
    hour: '小时',
    min: '分钟',
    second: '秒',
    codeError: '识别码未加载',
    updateError: '更新失败',
    customError: '自定义失败',
    tokenError: '生成Token失败，请重新点击页面按钮',
    netError: '服务器连接失败，请稍后',

    copy: '复制',
    introDBC: '了解DBC',
    loginErr: '请登录钱包',
    msgErrInfo1: '未初始化',
    msgErrInfo1000: '未初始化',
    msgErrInfo1001: '已经初始化过',
    msgErrInfo1002: '参数错误',
    msgErrInfo1003: '未开始串流',
    msgErrInfo1004: '已经在串流中',
    msgErrInfo1005: 'FD错误',
    msgErrInfo1006: '服务器连接失败',
    msgErrInfo1007: 'license错误或者过期',
    msgErrInfo1008: '内部错误',
    msgErrInfo1009: '认证失败',
    msgErrInfo1010: '认证超时',
    msgErrInfo1011: '创建视频流失败',
    msgErrInfo1012: '渲染失败',
    msgErrInfo1013: 'web连接失败',
    msgErrInfo1014: '连接信令超时',
    msgErrInfo1015: '打洞超时',
    msgErrInfo1016: '中转失败',
    msgErrInfo1017: '没显示器或者创建虚拟显示器失败',
    msgErrInfo1018: '设备不在线',

    comfirm: '确认'
  },
  meau: {
    nav1: '远程协助',
    nav2: '设置',
    nav3: '设备列表',
    nav4: '我的钱包',
    nav5: '游戏共享',
    login: '请登录',
    user1: '普通用户',
    user2: '皇冠NFT(专业版)',
    user3: '皇冠NFT(企业版)',
    time: '剩余有效期',
    devices: '设备数',
  },
  wallet: {
    newWallet: '新的钱包',
    openWallet: '打开钱包',
    createWallet: '创建钱包',
    tips1: '一定要记住密码！不能丢失，你需要这个密码和你的私钥文件才能解锁钱包',
    tips2: '存储你的私钥文件，将文件保存到安全的地方。比如手动抄写下来，放到保险箱',
    seed: '你的私钥',
    seed1: '私钥',
    keystore: 'Keystore文件',
    tips3: '千万不要丢失它!如果丢失将无法恢复',
    tips4: '也不要分享它！不要在微信、QQ、Facebook、Line、KakaoTalk、WhatsApp等社交软件互相传输。如果您在恶意钓鱼网站上使用此文件，您的资金将被窃取！',
    tips5: '一定要备份！就像它有一天可能价值数百万美元一样',
    create: '创建',
    finish: '完成',
    selectFile: '选择钱包文件',
    instructions: '钱包-使用说明',
    desc1: '登录钱包后，可以购买专业皇冠NFT，可以支持更多高级功能',
    details: '查看详细',
    desc2: '登录钱包后，可以购买DeepBrainChain Coin和DeepLink Coin',
    desc3: '登录钱包后，可以购买游戏或者其他的服务',
    desc4: '登录钱包后，可以共享本机游戏给其他玩家赚取DeepBrainChain Coin和DeepLink Coin',
  },
  home: {
    tip: '请保管好私钥信息，私钥丢失，任何人都将无法帮您找回数字资产！',
    address: '钱包地址',
    transfer: '转账',
    buy: '购买',
    exchange: '兑换',
    switch: '关闭钱包',
    hash: '转账记录Hash值',
    source: '源地址',
    target: '目标地址',
    result: '结果',
    number: '数量',

    currency: '币种',
    password: '密码',
    msg: '不能为空',
    msg1: '必须为数字类型',
    msg2: '转账成功',
    msg3: '转账失败',
  },
  view: {
    err: '验证码长度为六到十位字符串',
    delect: '删除成功',
    delecterr: '删除失败',
    title1: '允许远程控制本机',
    localcode: '本机识别码',
    localvercode: '本机验证码',
    updatevercode: '更新验证码',
    customvercode: '自定义验证码',
    join: '加入社区获取DeepLink Coin奖励',
    title2: '控制远程设备',
    remotecode: '远程设备识别码',
    remotevercode: '远程设备验证码',
    singlescreen: '单屏',
    expansionscreen: '扩展屏',
    remotenow: '立即远程',
    error1: '请输入正确的九位识别码',
    error2: '请输入正确的六位验证码',
    error3: '失败次数过多，请10分钟后再试',
  },
  device: {
    devList: '设备列表',
    adddev: '添加设备',
    intcafe: '云网吧',
    remaining: '剩余',
    renew: '续租',
    currentdev: '当前设备',
    none: '无',
    details: '订单详情',
    identifier: '识别码',
    vercode: '验证码',
    macsystem: '机器系统',
    remark: '备注',
    countdown: '剩余倒计时',
    local: '位置',
    rentalTime: '租用总时长',
    devdetails: '设备订单详情',
    index: '序号',
    leaseStartTime: '租用起始时间',
    rental: '租用时长',
    useTime: '实际使用时长',
    rentPaid: '支付租金数',
    refund: '退还租金数'
  },
  share: {
    name1: 'Games',
    name2: '我购买的游戏',
    name3: '共享我的游戏',
    name4: '验证人',
    label1: '距离',
    label2: '游戏名称',
    label3: 'GPU',
    label4: '收到租金数',
    label5: '对方钱包',
    label6: '返回的租金数',
    label7: '被没收的押金',
    label8: '验证人结果',
    btn1: '详情',
    btn2: '测试',
    btn3: 'Pay',
    btn4: '支付记录',
    btn5: '举报',
    btn6: '出租赚DBC',
    btn7: '修改',
    machine_id: '机器ID',
    pay: '支付',
    tab_name1: '游戏信息',
    tab_name2: '我的收入',
    tab_name3: '我的惩罚',

  },
  setting: {
    name1: '常规',
    name2: '安全',
    name3: '其他',
    rule: {
      title1: '启动与更新',
      label1: '开机自启动',
      label2: '常驻(退出软件后仍可被远程)',
      label3: '自动更新',
      title2: '热键设置',
      label4: '全屏/窗口化',
      label5: '最小化',
      label6: '切换屏幕',
      title3: '显示语言',
      label7: '设备语言',
      error: '热键冲突,重置中..'
    },
    safe: {
      title1: '自动锁屏',
      label1: '远程连接时自动锁屏',
      label2: '远程结束后自动锁屏',
      label3: '隐私屏被关闭后自动锁屏',
      title2: '验证码更新',
      label4: '永不',
      label5: '每次远程协助后',
      label6: '更新设置'
    },
    other: {
      title1: '远控时被控端静音',
      title2: '广域网带宽限制',
      title3: '被控端分辨率',
      title4: '被控端刷新率',
      title5: '虚拟显示器',
      label1: '同被控端',
      label2: '同主控端'
    }
  }
}