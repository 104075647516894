export default {
  public: {
    copysuccess: 'Copy succeeded',
    days: 'days',
    minimize: '最小化',
    closure: 'turn off an app',
    linkFail: 'サーバーへの接続が切断',
    linkSuccess: 'サーバに接続済み(セキュア暗号化リンク)',
    inputPwd: 'Please enter password',
    pwdError: 'Wrong password',
    seedError: 'Private key format error~',
    unlock: 'Unlock',
    inputSeed: 'Paste or enter the private key',
    walletbalance: 'Wallet balance',
    balance: 'balance',
    equivalent: 'Equivalent',
    price: 'Price',
    dollar: 'dollar',
    add: 'add',
    edit: 'edit',
    save: 'save',
    cancal: 'cancel',
    hour: 'hour',
    min: 'minute',
    second: 'Second',
    codeError: 'Identifier not loaded',
    updateError: 'Update failed',
    customError: 'Customization failed',
    tokenError: 'Failed to generate Token, please click the page button again',
    netError: 'Server connection failed, please wait',

    copy: 'copy',
    introDBC: ' Get to know DBC',
    loginErr: 'Please log in to your wallet',
    msgErrInfo1: 'not yet initialized',
    msgErrInfo1000: 'not yet initialized',
    msgErrInfo1001: 'already initialized',
    msgErrInfo1002: 'parameter error',
    msgErrInfo1003: 'not yet streaming',
    msgErrInfo1004: 'already streaming',
    msgErrInfo1005: 'FD error',
    msgErrInfo1006: 'failed to connect to server',
    msgErrInfo1007: 'license error or license expired',
    msgErrInfo1008: 'internal error',
    msgErrInfo1009: 'verification failed',
    msgErrInfo1010: 'verification expired',
    msgErrInfo1011: 'failed to create video streaming',
    msgErrInfo1012: 'failed to render',
    msgErrInfo1013: 'web connection failed',
    msgErrInfo1014: 'connecting signaling timeout',
    msgErrInfo1015: 'punching timeout',
    msgErrInfo1016: 'transit failed',
    msgErrInfo1017: 'No display or failed to create a virtual display',
    msgErrInfo1018: 'device offline',

    comfirm: '確認'
  },
  meau: {
    nav1: 'Remote Assist',
    nav2: '設置',
    nav3: 'Device List',
    nav4: 'My Wallet',
    login: 'Please sign in',
    user1: 'domestic consumer',
    user2: 'Professional Edition',
    user3: 'Enterprise Edition',
    time: 'validity period',
    devices: 'number of devices',
  },
  wallet: {
    newWallet: 'New wallet',
    openWallet: 'Open wallet',
    createWallet: 'Create wallet',
    tips1: 'Be sure to remember the password! Cannot be lost, you need this password and your private key file to unlock the wallet',
    tips2: 'Store your private key file, save the file in a safe place. For example, manually copy it down and put it in a safe',
    seed: 'Your private key',
    seed1: 'Private key',
    keystore: 'Keystore file',
    tips3: 'Never lose it! If lost it cannot be recovered',
    tips4: "Don't share it either! Do not transfer information to each other on WeChat, QQ, Facebook, Line, KakaoTalk, WhatsApp and other social software. If you use this file on a malicious phishing site, your funds will be stolen!",
    tips5: 'Be sure to back up! like it could be worth millions someday',
    create: 'Create',
    finish: 'Finish',
    selectFile: 'Select wallet file',
    instructions: 'Wallet - Instructions for use',
    desc1: 'After logging in to the wallet, you can purchase a professional crown NFT, which can support more advanced functions',
    details: 'View details',
    desc2: 'After logging into the wallet, you can purchase DeepBrainChain Coin and DeepLink Coin',
    desc3: 'After logging in to the wallet, you can purchase games or other services',
    desc4: 'After logging into the wallet, you can share the local game with other players to earn DeepBrainChain Coin and DeepLink Coin',
  },
  home: {
    tip: 'Please keep the private key information safe. If the private key is lost, no one will be able to help you find your digital assets!',
    address: 'Wallet address',
    transfer: 'Transfer',
    buy: 'Buy',
    exchange: 'Exchange',
    switch: 'Switch wallet',
    hash: 'Transfer Hash value',
    source: 'Source address',
    target: 'Target address',
    result: 'Result',
    number: 'Quantity',

    currency: '通貨',
    password: 'パスワード',
    msg: '空にすることはできません',
    msg1: '数値型である必要があります',
    msg2: '転送が成功しました',
    msg3: '転送に失敗しました',
  },
  view: {
    err: '確認コードの長さは 6 ～ 10 文字の文字列です。',
    delect: 'Deleted successfully',
    delecterr: 'Failed to delete',
    title1: '遠隔制御許可する',
    localcode: '自機識別子',
    localvercode: '自機認証コード',
    updatevercode: '認証コードの更新',
    customvercode: 'カスタム認証コード',
    join: 'コミュニティに加入してDeepLink Coinボーナスを取得する',
    title2: 'リモートデバイスの制御',
    remotecode: 'リモートデバイス識別子',
    remotevercode: 'リモートデバイス認証コード',
    singlescreen: 'シングルスクリーン',
    expansionscreen: '拡張スクリーン',
    remotenow: '今すぐリモート',
    error1: 'Please enter the correct nine-digit identification code',
    error2: 'Please enter the correct six-digit verification code',
    error3: '失敗が多すぎます。10 分後にもう一度お試しください。',
  },
  device: {
    devList: 'Device List',
    adddev: 'Add device',
    intcafe: '',// cloud internet cafe
    remaining: 'remaining',
    renew: 'renew lease',
    currentdev: 'Current device',
    none: 'none',
    details: 'Order Details',
    identifier: 'Identification code',
    vercode: 'Verification code',
    macsystem: 'Machine System',
    remark: 'Remark',
    countdown: 'Remaining countdown',
    local: 'Location',
    rentalTime: 'Total rental time',
    devdetails: 'Device order details',
    index: 'Index',
    leaseStartTime: 'Lease start time',
    rental: 'Rental duration',
    useTime: 'Actual usage time',
    rentPaid: 'Amount of rent paid',
    refund: 'Refunded rental amount'
  },
  setting: {
    name1: '一般',
    name2: '安全',
    name3: 'その他',
    rule: {
      title1: '起動及び更新',
      label1: '電源投入自己起動',
      label2: '常驻(ソフトからログアウトされてもリモート可能)',
      label3: '自動更新',
      title2: '短縮キー設定',
      label4: 'フルスクリーン/ウィンドウ化',
      label5: '最小化',
      label6: '画面を切り替える',
      title3: '表示言語',
      label7: 'Device language'
    },
    safe: {
      title1: '自動ロック',
      label1: 'リモート接続時の自動画面ロック',
      label2: 'リモート終了後、自動ロック',
      label3: 'プライバシースクリーンを閉じると自動的にロック',
      title2: '認証コードの更新',
      label4: '永遠',
      label5: 'リモートヘルプのたびに',
      label6: '設定を更新'
    },
    other: {
      title1: 'リモート時被制御側ミュートする',
      title2: 'WAN帯域幅の制限',
      title3: '被制御側解像度',
      title4: '被制御側リフレッシュレート',
      title5: '仮想モニタ',
      label1: '同被制御側',
      label2: '同マスタ側'
    }
  }
}