<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  // @ is an alias to /src
  import { defineComponent, ref } from "vue";
  export default defineComponent({
    name: 'App',
  })
</script>

<style lang="scss">
#app {
  font-style: normal;
  font-family: 'PingFang SC';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  width: 1438px;
  user-select: none;
  overflow: hidden;
  // border: 2px solid #eee;
  
}
body, p, div, span, li {
  margin: 0;
}
</style>

<style>
.el-message__content {
  font-size: 18px !important;
}
</style>
