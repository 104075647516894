<template>
  <div class="shop" :class="{lan_En : lan != 'zh'}">
    <div class="content">
      <div class="tabs">
        <div class="tab-pane" :class="{active: chooseTab == 1}" @click="switchTab(1)">NFT</div>
        <div class="tab-pane" :class="{active: chooseTab == 2}" @click="switchTab(2)">Cloud GameFi 节点</div>
      </div>
      <div class="pane-detail" :class="{hide: chooseTab == 2}">
        <div class="game-list">
          <el-scrollbar max-height="700px">
            <div class="game-li" v-for="(el, index) in gameList" :key="index">
              <div class="img">
                <el-image v-if="el.img" style="width: 100%; height: 100%" :src="el.img" fit="cover" />
                <el-image v-else style="width: 100%; height: 100%"/>
              </div>
              <div class="name">
                <p>{{ el.name }}</p>
              </div>
            </div>
          </el-scrollbar>
        </div>
        <el-scrollbar max-height="700px">
          <div class="game-info">
              <div class="details" v-for="(el, index) in gameInfo" :key="index">
                <div class="img">
                  <el-image v-if="el.img" style="width: 100%; height: 100%" :src="el.img" fit="cover" />
                  <el-image v-else style="width: 100%; height: 100%"/>
                </div>
                <div class="title">{{ el.title }}</div>
                <div class="num">
                  <div class="icon">
                    <el-image v-if="el.img" style="width: 100%; height: 100%" :src="el.icon" fit="cover" />
                    <el-image v-else style="width: 100%; height: 100%"/>
                  </div>
                  <p>{{ el.number }}</p>
                </div>
                <div class="batch">{{ el.batch1 }}/{{ el.batch2 }} 当前批次</div>
                <div class="desc">{{ el.desc }}</div>
              </div>
          </div>
        </el-scrollbar>
      </div>
      <div class="pane-detail" :class="{hide: chooseTab == 1}">
        <div class="game-list">
          <el-scrollbar max-height="700px">
            <div class="game-li" v-for="(el, index) in gameList" :key="index">
              <div class="img">
                <el-image v-if="el.img" style="width: 100%; height: 100%" :src="el.img" fit="cover" />
                <el-image v-else style="width: 100%; height: 100%"/>
              </div>
              <div class="name">
                <p>{{ el.name }}</p>
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="game-cont">
          <el-scrollbar max-height="700px">
            <div class="content-info">
              <div class="introduce">
                <div class="intr-img">
                  <el-image style="width: 100%; height: 100%" src="https://img2.baidu.com/it/u=2969169350,1551254405&fm=253&app=120&size=w931&n=0&f=PNG&fmt=auto?sec=1672506000&t=1a7b5e1ceb95c6de1f7c4f9434c919e6" fit="cover" />
                </div>
                <div class="intr-cont">
                  <div class="intr-title">spider tanks planetary node license</div>
                  <div class="norms">当前产品规格</div>
                  <div class="intr-desc">
                    town starspider tanks planetary node licensespider tanks planetary node license  tanks planetary node licensespider
                  </div>
                </div>
              </div>
              <div class="trade">
                <div class="nav">选择货币</div>
                <div class="choose">
                  <div class="img"></div>
                  <div class="text">
                    <p>USDT</p>
                    <span class="icon"></span>
                  </div>
                </div>
                <div class="nav">价格</div>
                <div class="price">
                  <div class="img"></div>
                  <p>256,563.600</p>
                </div>
                <div class="nav">
                  <p>投放时间表 <span class="icon">i</span></p>
                  <div class="desc">批次2预23</div>
                </div>
                <div class="surplus">4/100 剩余</div>
                <div class="tips">2,400/8,100 剩余库存</div>
                <div class="nav">选择货币</div>
                <div class="number">
                  <span class="icon">-</span>
                  <input type="text" :value="value">
                  <span class="icon">+</span>
                </div>
              </div>
              <div class="main">
                <el-image style="width: 100%; height: 100%" src="https://img2.baidu.com/it/u=2969169350,1551254405&fm=253&app=120&size=w931&n=0&f=PNG&fmt=auto?sec=1672506000&t=1a7b5e1ceb95c6de1f7c4f9434c919e6" fit="cover" />
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed } from "vue"
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'shop',
    setup() {
      const store = useStore()
      const chooseTab = ref(2)
      const value = ref(23)
      const gameList = ref([
        {
          name: 'eternal paradoxparad',
          img: 'https://img2.baidu.com/it/u=2969169350,1551254405&fm=253&app=120&size=w931&n=0&f=PNG&fmt=auto?sec=1672506000&t=1a7b5e1ceb95c6de1f7c4f9434c919e6'
        },
        {
          name: 'fortitude',
          img: ''
        },
        {
          name: 'fortitude',
          img: 'https://img2.baidu.com/it/u=2969169350,1551254405&fm=253&app=120&size=w931&n=0&f=PNG&fmt=auto?sec=1672506000&t=1a7b5e1ceb95c6de1f7c4f9434c919e6'
        },
        {
          name: 'fortitude',
          img: 'https://img2.baidu.com/it/u=2969169350,1551254405&fm=253&app=120&size=w931&n=0&f=PNG&fmt=auto?sec=1672506000&t=1a7b5e1ceb95c6de1f7c4f9434c919e6'
        },
        {
          name: 'fortitude',
          img: 'https://img2.baidu.com/it/u=2969169350,1551254405&fm=253&app=120&size=w931&n=0&f=PNG&fmt=auto?sec=1672506000&t=1a7b5e1ceb95c6de1f7c4f9434c919e6'
        },
        {
          name: 'fortitude',
          img: 'https://img2.baidu.com/it/u=2969169350,1551254405&fm=253&app=120&size=w931&n=0&f=PNG&fmt=auto?sec=1672506000&t=1a7b5e1ceb95c6de1f7c4f9434c919e6'
        }
      ])
      const gameInfo = ref([
        {
          img: 'https://img2.baidu.com/it/u=2969169350,1551254405&fm=253&app=120&size=w931&n=0&f=PNG&fmt=auto?sec=1672506000&t=1a7b5e1ceb95c6de1f7c4f9434c919e6',
          title: 'Wizards workshop',
          icon: '',
          number: 1000.00,
          batch1: 200,
          batch2: 500,
          desc: 'town star'
        },
        {
          img: '',
          title: 'Wizards workshop',
          icon: '',
          number: 1000.00,
          batch1: 200,
          batch2: 500,
          desc: 'town star'
        },
        {
          img: 'https://img2.baidu.com/it/u=2969169350,1551254405&fm=253&app=120&size=w931&n=0&f=PNG&fmt=auto?sec=1672506000&t=1a7b5e1ceb95c6de1f7c4f9434c919e6',
          title: 'Wizards workshop',
          icon: '',
          number: 1000.00,
          batch1: 200,
          batch2: 500,
          desc: 'town star'
        },
        {
          img: 'https://img2.baidu.com/it/u=2969169350,1551254405&fm=253&app=120&size=w931&n=0&f=PNG&fmt=auto?sec=1672506000&t=1a7b5e1ceb95c6de1f7c4f9434c919e6',
          title: 'Wizards workshop',
          icon: '',
          number: 1000.00,
          batch1: 200,
          batch2: 500,
          desc: 'town star'
        }
      ])
      let lan = computed(() => {
        return store.state.lan
      })
      const switchTab = (val) => {
        chooseTab.value = val
      }
      return {
        lan,
        value,
        chooseTab,
        gameList,
        gameInfo,
        switchTab
      }
    }
  })
</script>

<style scoped lang="scss">
.shop {
  width: 100%;
  margin: auto;
  max-width: 1220px;
  font-style: normal;
  font-family: 'PingFang SC';
  .content {
    width: 100%;
    padding: 20px 30px 0;
    box-sizing: border-box;
    .tabs {
      width: 100%;
      display: flex;
      padding-left: 15px;
      align-items: center;
      box-sizing: border-box;
      .tab-pane {
        height: 54px;
        color: #666;
        font-size: 24px;
        padding: 0 30px;
        cursor: pointer;
        line-height: 54px;
        margin-left: 15px;
        border-radius: 20px 20px 0 0;
        background: rgba(54, 63, 94, 0.08);
        &.active {
          color: #22283E;
          background: #FFC11C;
        }
      }
    }
    .pane-detail {
      width: 100%;
      display: flex;
      min-height: 100px;
      border-radius: 30px;
      background: rgba(54, 63, 94, 0.04);
      &.hide {
        display: none;
      }
      .game-list {
        width: 210px;
        border-right: 2px solid rgba(54, 63, 94, 0.06);
        .game-li {
          width: 100%;
          display: flex;
          padding: 0 20px;
          margin-top: 20px;
          align-items: center;
          box-sizing: border-box;
          .img {
            width: 45px;
            height: 45px;
            overflow: hidden;
            margin-right: 10px;
            border-radius: 100%;
          }
          .name {
            flex: 1;
            display: flex;
            font-size: 16px;
            color: #666666;
            overflow: hidden;
            line-height: 1.2;
            align-items: center;
            p {
              width: 100%;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              text-overflow: ellipsis;
              -webkit-box-orient: vertical;
            }
          }
          &:last-child {
            margin-bottom: 20px;
          }
        }
      }
      .game-info {
        flex: 1;
        padding: 24px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .details {
          width: 285px;
          overflow: hidden;
          border-radius: 20px;
          padding-bottom: 15px;
          background: #FFFFFF;
          margin:  0 20px 16px 0;
          box-shadow: 0px 2px 24px rgba(54, 63, 94, 0.08);
          &:nth-child(3n) {
            margin-right: 0;
          }
          .img {
            width: 285px;
            height: 285px;
            overflow: hidden;
          }
          .title {
            width: 95%;
            font-size: 24px;
            line-height: 1.2;
            color: #363F5E;
            margin: 15px auto 10px;
          }
          .num {
            width: 90%;
            display: flex;
            margin: 0 auto 8px;
            align-items: center;
            .icon {
              width: 22px;
              height: 22px;
              overflow: hidden;
              margin-right: 5px;
              border-radius: 100%;
            }
            p {
              flex: 1;
              font-size: 14px;
              font-weight: 400;
              color: #999999;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .batch {
            margin-left: 5%;
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            padding: 4px 10px;
            border-radius: 25px;
            display: inline-block;
            background: rgba(54, 63, 94, 0.08);
          }
          .desc {
            width: 90%;
            font-size: 16px;
            color: #666666;
            line-height: 22px;
            margin: 8px auto 0;
          }
        }
        
      }
      .game-cont {
        flex: 1;
      }
      .content-info {
        flex: 1;
        padding: 24px;
        display: flex;
        flex-wrap: wrap;
        // align-items: center;
        justify-content: space-between;
        .introduce {
          width: 600px;
          overflow: hidden;
          border-radius: 20px;
          background: #FFFFFF;
          box-shadow: 0px 2px 24px rgba(54, 63, 94, 0.08);
          .intr-img {
            width: 100%;
            height: 300px;
          }
          .intr-cont {
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
            .intr-title {
              width: 100%;
              font-size: 24px;
              color: #363F5E;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .norms {
              width: 99px;
              height: 27px;
              margin: 10px 0;
              font-size: 12px;
              font-weight: 400;
              color: #1B60EE;
              line-height: 27px;
              text-align: center;
              border-radius: 81px;
              letter-spacing: 0.04em;
              background: rgba(27, 96, 238, 0.05);
            }
            .intr-desc {
              width: 100%;
              font-size: 16px;
              font-weight: 400;
              color: #666666;
              line-height: 22px;
              text-transform: capitalize;
            }
          }
        }
        .trade {
          width: 280px;
          padding: 20px;
          border-radius: 20px;
          background: #FFFFFF;
          box-sizing: border-box;
          box-shadow: 0px 2px 24px rgba(54, 63, 94, 0.08);
          .nav {
            font-size: 16px;
            line-height: 22px;
            color: #666666;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .icon {
              width: 12px;
              height: 12px;
              color: #999;
              font-size: 12px;
              margin-left: 5px;
              line-height: 12px;
              text-align: center;
              border-radius: 12px;
              display: inline-block;
              border: 2px solid #999999;
            }
            .desc {
              min-width: 68px;
              height: 20px;
              padding: 0 10px;
              font-size: 12px;
              color: #999999;
              line-height: 21px;
              text-align: center;
              border-radius: 10px;
              text-transform: capitalize;
              background: rgba(54, 63, 94, 0.08);
            }
          }
          .choose {
            width: 100%;
            height: 40px;
            display: flex;
            cursor: pointer;
            margin: 10px 0 24px;
            padding: 0 18px 0 12px;
            align-items: center;
            border-radius: 30px;
            box-sizing: border-box;
            justify-content: space-between;
            background: rgba(54, 63, 94, 0.05);
            .img {
              width: 28px;
              height: 28px;
              background: url(../assets/icon.png) no-repeat 100%/100%;
            }
            .text {
              flex: 1;
              margin-left: 8px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .icon {
                width: 4px;
                height: 8px;
                background: url(../assets/arrow.png) no-repeat center;
                background-size: 4px 8px;
              }
            }
          }
          .price {
            display: flex;
            margin: 8px 0 24px;
            align-items: center;
            .img {
              width: 28px;
              height: 28px;
              margin-right: 6px;
              background: url(../assets/icon.png) no-repeat 100%/100%;
            }
          }
          .surplus {
            width: 100%;
            height: 40px;
            display: flex;
            cursor: pointer;
            margin: 10px 0 8px;
            align-items: center;
            border-radius: 30px;
            box-sizing: border-box;
            padding: 0 18px 0 12px;
            justify-content: center;
            background: rgba(54, 63, 94, 0.05);
          }
          .tips {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
            text-align: center;
            margin-bottom: 24px;
            padding-bottom: 24px;
            text-transform: capitalize;
            border-bottom: 1px solid rgba(54, 63, 94, 0.08);;
          }
          .number {
            width: 100%;
            height: 40px;
            display: flex;
            cursor: pointer;
            padding: 0 18px;
            color: #363F5E;
            margin: 10px 0 8px;
            align-items: center;
            border-radius: 30px;
            box-sizing: border-box;
            justify-content: space-between;
            background: rgba(54, 63, 94, 0.05);
            .icon {
              width: 18px;
              height: 18px;
              display: inline-block;
              font-weight: 700;
              font-size: 18px;
              line-height: 18px;
              text-align: center;
              border-radius: 100%;
              color: rgba(54, 63, 94, 0.68);
              border: 2px solid rgba(54, 63, 94, 0.68);
            }
            input {
              flex: 1;
              border: none;
              height: 30px;
              outline: none;
              text-align: center;
              background: rgba(54, 63, 94, 0.01);
              &:focus {
                border: none;
                height: 30px;
                outline: none;
                background: rgba(54, 63, 94, 0.01);
              }
              
            }
          }
        }
        .main {
          width: 100%;
          height: 250px;
          margin-top: 20px;
          overflow: hidden;
          border-radius: 20px;
        }
      }
    }
  }
}
</style>

