export default {
  public: {
    copysuccess: 'successfully copied',
    days: 'days',
    minimize: 'Minimize',
    closure: 'Close',
    linkFail: 'Disconnected server',
    linkSuccess: 'Ready to connect (secure connection)',
    inputPwd: 'Please enter password',
    pwdError: 'Password incorrect',
    seedError: 'Private key format incorrect',
    unlock: 'Unlock',
    inputSeed: 'Paste or enter private key',
    walletbalance: 'Wallet balance',
    balance: 'Balance',
    equivalent: 'Equivalent of',
    price: 'Price',
    dollar: 'US dollar',
    add: 'Add',
    edit: 'Edit',
    save: 'Save',
    cancal: 'Cancel',
    hour: 'Hour',
    min: 'Minute',
    second: 'Second',
    codeError: 'Identifier did not load',
    updateError: 'Update failed',
    customError: 'Customization failed',
    tokenError: 'Token generation failed, please click the button again',
    netError: 'Failed to connect to server, please wait',

    copy: 'copy',
    introDBC: ' Get to know DBC',
    loginErr: 'Please log in to your wallet',
    msgErrInfo1: 'not yet initialized',
    msgErrInfo1000: 'not yet initialized',
    msgErrInfo1001: 'already initialized',
    msgErrInfo1002: 'parameter error',
    msgErrInfo1003: 'not yet streaming',
    msgErrInfo1004: 'already streaming',
    msgErrInfo1005: 'FD error',
    msgErrInfo1006: 'failed to connect to server',
    msgErrInfo1007: 'license error or license expired',
    msgErrInfo1008: 'internal error',
    msgErrInfo1009: 'verification failed',
    msgErrInfo1010: 'verification expired',
    msgErrInfo1011: 'failed to create video streaming',
    msgErrInfo1012: 'failed to render',
    msgErrInfo1013: 'web connection failed',
    msgErrInfo1014: 'connecting signaling timeout',
    msgErrInfo1015: 'punching timeout',
    msgErrInfo1016: 'transit failed',
    msgErrInfo1017: 'No display or failed to create a virtual display',
    msgErrInfo1018: 'device offline',

    comfirm: 'Confirm'
  },
  meau: {
    nav1: 'Remote Assist',
    nav2: 'Options',
    nav3: 'Device List',
    nav4: 'My Wallet',
    login: 'Log in',
    user1: 'Regular user',
    user2: ' Crown NFT(profesional version)',
    user3: '  NFT(enterprise version)',
    time: 'Remaining valid time',
    devices: 'Device number(s)',
  },
  wallet: {
    newWallet: 'New wallet',
    openWallet: 'Open wallet',
    createWallet: 'Create wallet',
    tips1: 'You must remember your password! You must not lose it, you need your password and your private key to open your wallet.',
    tips2: 'Store your private key file, save it in a safe place. Write it down with pen and paper, keep the piece of paper in a safe or in a safe place.',
    seed: 'Your private key',
    seed1: 'Private key',
    keystore: 'Keystore document',
    tips3: 'DO NOT lose it! If you lose it it is not reversible.',
    tips4: 'DO NOT share it! DO NOT send it on any social media or communication software such as WeChat, QQ, Facebook, Line, KakaoTalk, WhatsApp. If you use this document on a malicious fishing website your asset WILL BE STOLEN!',
    tips5: 'You must back up your file! Treat it as if it will one day worth millions of US dollar.',
    create: 'Create',
    finish: 'Complete',
    selectFile: 'Select wallet file',
    instructions: 'Wallet-user manual',
    desc1: 'Once you log in to your wallet, you can buy professional crown NFT, to support you using more higher level functions.',
    details: 'Check details',
    desc2: 'Once you log in to your wallet, you can buy DeepBrainChain Coin and DeepLink Coin',
    desc3: 'Once you log in to your wallet, you can buy games or other services',
    desc4: 'Once you log in to your wallet, you can share your game(s) on your computer with other gamers to earn DeepBrainChain Coin and DeepLink Coin',
  },
  home: {
    tip: 'Please keep your private key information safe, if you lose your private key, no one can retrieve your digital asset(s) for you.',
    address: 'Wallet address',
    transfer: 'Transfer',
    buy: 'Buy',
    exchange: 'Exchange',
    switch: 'Close wallet',
    hash: 'Transaction Hash value',
    source: 'Withdraw address',
    target: 'Deposit address',
    result: 'Result',
    number: 'Amount',

    currency: 'Currency',
    password: 'Password',
    msg: 'Cannot be empty',
    msg1: 'Must be a number',
    msg2: 'Transfer Successful',
    msg3: 'Transfer Failed',
  },
  view: {
    err: 'The verification code length is a string of six to ten characters',
    delect: 'Successful deleted',
    delecterr: 'Failed to delete',
    title1: 'Allow Remote Control',
    localcode: 'Your Device ID',
    localvercode: 'Password',
    updatevercode: 'Refresh',
    customvercode: 'Customize',
    join: 'Join the community to get DeepLink Coin rewards',
    title2: 'Control Remote Device',
    remotecode: 'Partner Device ID',
    remotevercode: 'Password',
    singlescreen: 'Default',
    expansionscreen: 'Extended screen',
    remotenow: 'Connect',
    error1: 'Please enter the correct 9 digit identifier',
    error2: 'Please enter the correct 6 digit verification code',
    error3: 'Too many failures, please try again in 10 minutes',
  },
  device: {
    devList: 'Device list',
    adddev: 'Add device',
    intcafe: 'Cloud Internet Cafe',
    remaining: 'Remaining',
    renew: 'Renew rental',
    currentdev: 'Current device',
    none: 'None',
    details: 'Order detail',
    identifier: 'Identifier',
    vercode: 'Verification code',
    macsystem: 'Machine system',
    remark: 'Note',
    countdown: 'Remaining time countdown',
    local: 'Location',
    rentalTime: 'Rental total time',
    devdetails: 'Device order detail',
    index: 'Serial number',
    leaseStartTime: 'Rental start time',
    rental: 'Rental time',
    useTime: 'Actual time used',
    rentPaid: 'Rental fee paid',
    refund: 'Refund rental fee'
  },
  setting: {
    name1: 'General',
    name2: 'Security',
    name3: 'More..',
    rule: {
      title1: 'Start And Update',
      label1: 'Power on start(Unattended)',
      label2: 'Service(Still Can be remoted after exiting)',
      label3: 'Auto update',
      title2: 'Hotkey Settings',
      label4: 'Full/Windowed',
      label5: 'Minimize',
      label6: 'Switch Screen',
      title3: 'Language',
      label7: 'Device language',
      error: 'hotkey conflict, reset..'
    },
    safe: {
      title1: 'Auto lock Screen',
      label1: 'Lock Screen On Connect',
      label2: 'Lock Screen On Closed',
      label3: 'Lock Screen On privacyScreen Broken',
      title2: 'Update passcode',
      label4: 'Never',
      label5: 'After remote assist',
      label6: 'Update settings'
    },
    other: {
      title1: 'Mute controlled device',
      title2: 'Bandwidth(Wan)',
      title3: 'Resolution',
      title4: 'Fps',
      title5: 'Virtual Display',
      label1: 'Keep Host',
      label2: 'Use Client'
    }
  }
}