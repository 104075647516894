<template>
  <div>
    <div
      class="mouseMove"
      @mousedown="mousedown"
    ></div>
    <input type="button" @click="close" value="关闭">
    <input type="button" @click="minimized" value="最小化">
    <input type="button" @click="href" value="跳转默认浏览器打开">
  </div>
</template>

<script>
  import { QWebChannel } from '@/utils/qwebchannel'
  import { defineComponent, computed, ref } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'Reward',
    setup() {
      const store = useStore()
      const initialX = ref(0)
      const initialY = ref(0)
      const ismove = ref(false)
      let lan = computed(() => {
        return store.state.lan
      })
      new QWebChannel(qt.webChannelTransport,
        function(channel) {
          // doc 相当于QT的接口对象，由QT指定名称
          window.doc = channel.objects.doc;
        }
      );
      const close = async () => {
        let data = await doc.closeWindow()
        console.log(data, 'data');
      }
      const minimized = async () => {
        // let data = await doc.showMinimized()
        let data = await doc.say('234')
        console.log(data, 'data');
      }
      const mousedown = (event) => {
        // doc.startMoveWindow()
        initialX.value = event.screenX
        initialY.value = event.screenY
        ismove.value = true

        document.onmousemove = (ev) => {
          if (!ismove.value) return false;
          let offestX = ev.screenX - initialX.value
          let offestY = ev.screenY - initialY.value
          initialX.value = ev.screenX
          initialY.value = ev.screenY
          doc.moveWindow(offestX, offestY)
        };
        document.onmouseup = (ev) => {
          if (!ismove.value) return false;
          ismove.value = false;
        };
      }
      const mousemove = (event) => {
        if (ismove.value) {
          let offestX = event.screenX - initialX.value
          let offestY = event.screenY - initialY.value
          initialX.value = event.screenX
          initialY.value = event.screenY
          doc.moveWindow(offestX, offestY)
          
        }
      }
      const mouseup = (event) => {
        // doc.stopMoveWindow()
        ismove.value = false
      }
      const href = () => {
        doc.openLinkUrl('https://baidu.com')
      }
      return {
        lan,
        href,
        close,
        minimized,
        mousedown,
        mousemove,
        mouseup
      };
    }
  })
</script>


<style lang="scss">
.mouseMove {
  width: 100px;
  height: 100px;
  background: red;
}
</style>

