<template>
  <common-head />
  <div class="content">
    <common-meau class="sidebar-container" />
    <div class="main-cont">
      <!-- <transition name="el-fade-in-linear"> -->
        <router-view/>
      <!-- </transition> -->
    </div>
  </div>
</template>
<script>
import commonHead from '@/components/commonHead.vue';
import commonMeau from '@/components/commonMeau.vue';

export default {
  name: 'layout',
  components: {
    commonHead,
    commonMeau
  }
}
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .sidebar-container {
    width: 220px;
  }
  .main-cont {
    // flex: 1;
    width: 1220px;
    height: 868px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
</style>