import { QWebChannel } from './qwebchannel.js'


export let objdoc = new QWebChannel(qt.webChannelTransport,
  function(channel) {
    // doc 相当于QT的接口对象，由QT指定名称
    // objdoc = channel.objects.doc;
    return channel.objects.doc;
  }
);


