<template>
  <div class="header-cont" @mousedown="mousedown">
    <div class="left">
      <div class="logo">
        <el-image style="width: 100%; height: 100%" :src="logoImg" fit="cover"/>
      </div>
      <div class="status">
        <div class="point" :class="{error: onlineStatus != 1}"></div>
        <p>{{onlineStatus == 1 ? $t('public.linkSuccess') : $t('public.linkFail')}}&nbsp;·&nbsp;</p>
        <p>VER {{version}}</p>
      </div>
    </div>
    <div class="right">
      <el-tooltip :content="$t('public.minimize')" placement="bottom" effect="light">
        <div class="img" @click="minimize">
          <el-image style="width: 100%; height: 100%" :src="smallImg" fit="cover"/>
        </div>
      </el-tooltip>
      <el-tooltip :content="$t('public.closure')" placement="bottom" effect="light">
        <div class="img" @click="closeWin">
          <el-image style="width: 100%; height: 100%" :src="closeImg" fit="cover"/>
        </div>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance, ref, computed, onMounted } from "vue"
import { useStore } from 'vuex'
import { useI18n } from "vue-i18n"
import logo from '@/assets/logo.png'
import small from '@/assets/small.png'
import close from '@/assets/close.png'
export default defineComponent({
    name: 'commonHead',
    setup() {
      const { t, locale } = useI18n()
      const store = useStore()
      const { proxy } = getCurrentInstance()
      const onlineStatus = computed(() => store.state.onlineStatus)
      const logoImg = ref(logo)
      const smallImg = ref(small)
      const closeImg = ref(close)
      const initialX = ref(0)
      const initialY = ref(0)
      const ismove = ref(false)
      const version = ref('')
      const minimize = async () => {
        proxy.$objdoc.doc.showMinimized()
      }
      const closeWin = () => {
        proxy.$objdoc.doc.closeWindow()
      }
      const mousedown = (event) => {
        initialX.value = event.screenX
        initialY.value = event.screenY
        ismove.value = true

        document.onmousemove = (ev) => {
          if (!ismove.value) return false;
          let offestX = ev.screenX - initialX.value
          let offestY = ev.screenY - initialY.value
          initialX.value = ev.screenX
          initialY.value = ev.screenY
          proxy.$objdoc.doc.moveWindow(offestX, offestY)
        };
        document.onmouseup = (ev) => {
          if (!ismove.value) return false;
          ismove.value = false;
        };
      }
      const getVer = async () => {
        version.value = await proxy.$objdoc.doc.getAppVersion()
      }
      onMounted(() => {
        getVer()
      });
      return {
        logoImg,
        smallImg,
        closeImg,
        version,
        onlineStatus,
        minimize,
        closeWin,
        mousedown
      }
    }
  })
</script>

<style scoped lang="scss">
.header-cont {
  width: 100%;
  height: 80px;
  display: flex;
  padding: 25px 40px;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  background: url(../assets/bg.png) no-repeat 100%/100%;
  .left {
    display: flex;
    align-items: center;
    .logo {
      width: 257px;
      height: 30px;
      pointer-events: none;
    }
    .status {
      display: flex;
      height: 29px;
      font-size: 12px;
      padding: 2px 16px;
      font-weight: 400;
      margin-left: 24px;
      border-radius: 56px;
      align-items: center;
      justify-content: space-between;
      color: rgba(255, 255, 255, 0.9);
      background: rgba(255, 255, 255, 0.12);
      .point {
        width: 10px;
        height: 10px;
        margin-right: 5px;
        border-radius: 10px;
        background: #22E38F;
        &.error {
          background: red; 
        }
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    // pointer-events: none;
    .img {
      width: 30px;
      height: 30px;
      cursor: pointer;
      margin-left: 30px;
    }
  }
}
</style>