<template>
  <div class="meau-cont">
    <div class="userInfo" v-if="address">
      <div class="userimg">
        <el-image style="width: 100%; height: 100%" :src="userInfo.img" fit="cover"/>
      </div>
      <div class="info " :class="{ lan_En: lan != 'zh' }">
        <p class="level">{{ levelType[userInfo.level] }}</p>
        <!-- <p class="time"><span class="icon"></span> {{$t('meau.time')}}: {{ userInfo.effectiveTime }} {{$t('public.days')}}</p> -->
        <p class="equipment"><span class="icon"></span> {{$t('meau.devices')}}: {{ userInfo.equipment }}/{{ userInfo.total }}</p>
      </div>
      <div class="wallet">
        <p>{{ address }}</p>
        <el-tooltip :content="$t('public.copy')" placement="bottom" effect="light">
          <span class="icon" @click="copyWallet(address)"></span>
        </el-tooltip>
      </div>
    </div>
    <div class="noLogin" v-else>
      <div class="userimg"></div>
      <div class="btn">{{$t('meau.login')}}</div>
    </div>
    <div class="meauList">
      <div class="meauli" :class="{active: chooseNum == el.idx}" v-for="(el, index) in meauList" :key="index" @click="chooseTab(el, el.idx)">
        <div class="icon">
          <el-image style="width: 100%; height: 100%" :src="chooseNum == el.idx ? el.icon1 : el.icon" fit="cover"/>
        </div>
        {{ el.text }}
      </div>
    </div>
  </div>
</template> 

<script>
import { inject, defineComponent, ref, getCurrentInstance, onMounted, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from "vue-i18n"
import { useRoute, useRouter } from 'vue-router'
import remote from '@/assets/remote.png'
import remote1 from '@/assets/remote1.png'
import shop from '@/assets/shop.png'
import shop1 from '@/assets/shop1.png'
import my from '@/assets/my.png'
import my1 from '@/assets/my1.png'
import Cloud3A from '@/assets/3a.png'
import Cloud3A1 from '@/assets/3a1.png'
import gameFi from '@/assets/gameFi.png'
import gameFi1 from '@/assets/gameFi1.png'
import internet from '@/assets/internet.png'
import internet1 from '@/assets/internet1.png'
import device from '@/assets/device.png' 
import device1 from '@/assets/device1.png' 
import share from '@/assets/share.png'
import share1 from '@/assets/share1.png'
import setting from '@/assets/setting.png'
import setting1 from '@/assets/setting1.png'
import userImg from '@/assets/userImg.png'
export default defineComponent({
  name: 'commonMeau',
  setup() {
    const message = inject('$message') 
    const { t, locale } = useI18n()
    const store = useStore()
    const { proxy } = getCurrentInstance()
    const router = useRouter()
    const route = useRoute()
    const levelType = ref({
      0: t('meau.user1'),
      1: t('meau.user2'),
      2: t('meau.user3')
    })
    const userInfo = ref({
      img: userImg,
      level: 0, // 0 普通用户 1 皇冠NFT (专业版) 2 皇冠NFT (企业版)
      effectiveTime: '200', // 单位：天
      equipment: 1, // 已用设备
      total: 10, // 可用设备数
    })
    let address = computed(() => store.state.account)
    let lan = computed(() => store.state.lan)
    const chooseNum = ref(null)
    const meauList = ref([ 
      {
        icon: my,
        icon1: my1,
        text: t('meau.nav4'),
        meau: '/index',
        idx: 0
      },
      {
        icon: remote,
        icon1: remote1,
        text: t('meau.nav1'),
        meau: '/view',
        idx: 1
      },
      // {
      //   icon: internet,
      //   icon1: internet1,
      //   text: '云网吧',
      //   meau: '/cybercafe',
      //   idx: 5
      // },
      // {
      //   icon: device,
      //   icon1: device1,
      //   text: t('meau.nav3'),
      //   meau: '/deviceList',
      //   idx: 6
      // },
      // {
      //   icon: share,
      //   icon1: share1,
      //   text: t('meau.nav5'),
      //   meau: '/gameShare',
      //   idx: 7
      // },
      {
        icon: setting,
        icon1: setting1,
        text: t('meau.nav2'),
        meau: '/setting',
        idx: 8
      },
      // {
      //   icon: shop,
      //   icon1: shop1,
      //   text: '商店',
      //   meau: '/shop'
      // },
      // {
      //   icon: Cloud3A,
      //   icon1: Cloud3A1,
      //   text: 'Cloud 3A',
      //   meau: ''
      // },
      // {
      //   icon: gameFi,
      //   icon1: gameFi1,
      //   text: 'Cloud GameFi',
      //   meau: ''
      // },
    ])
    const copyWallet = (val) => {
      event.stopPropagation()
      proxy.$copyText(String(val)).then(() => {
        message({
          message: t('public.copysuccess'),
          grouping: true,
          type: 'success'
        })
      })
    }
    const chooseTab = (el, idx) => {
      chooseNum.value = idx
      router.push({path: el.meau})
    }
    const chooseStatus = () => {
      if (route.path == '/index') {
        chooseNum.value = 0
      } else if (route.path == '/view') {
        chooseNum.value = 1
      } else if (route.path == '/cybercafe') {
        chooseNum.value = 5
      } else if (route.path == '/deviceList') {
        chooseNum.value = 6
      } else if (route.path == '/gameShare') {
        chooseNum.value = 7
      }  else if (route.path == '/setting') {
        chooseNum.value = 8
      }
    }
    onMounted(() => {
      chooseStatus()
    })
    watch(
      () => locale.value, () => {
        meauList.value = [
        {
          icon: my,
          icon1: my1,
          text: t('meau.nav4'),
          meau: '/index',
          idx: 0
        },
        {
          icon: remote,
          icon1: remote1,
          text: t('meau.nav1'),
          meau: '/view',
          idx: 1
        },
        // {
        //   icon: internet,
        //   icon1: internet1,
        //   text: '云网吧',
        //   meau: '/cybercafe',
        //   idx: 5
        // },
        // {
        //   icon: device,
        //   icon1: device1,
        //   text: t('meau.nav3'),
        //   meau: '/deviceList',
        //   idx: 6
        // },
        // {
        //   icon: share,
        //   icon1: share1,
        //   text: t('meau.nav5'),
        //   meau: '/gameShare',
        //   idx: 7
        // },
        {
          icon: setting,
          icon1: setting1,
          text: t('meau.nav2'),
          meau: '/setting',
          idx: 8
        },
        // {
        //   icon: shop,
        //   icon1: shop1,
        //   text: '商店',
        //   meau: '/shop'
        // },
        // {
        //   icon: Cloud3A,
        //   icon1: Cloud3A1,
        //   text: 'Cloud 3A',
        //   meau: ''
        // },
        // {
        //   icon: gameFi,
        //   icon1: gameFi1,
        //   text: 'Cloud GameFi',
        //   meau: ''
        // }
        ]
        levelType.value = {
          0: t('meau.user1'),
          1: t('meau.user2'),
          2: t('meau.user3')
        }
      }
    )
    watch(() => route.path, (newval) => {
      if (newval == '/index') {
        chooseNum.value = 0
      } else if (newval == '/view') {
        chooseNum.value = 1
      } else if (route.path == '/cybercafe') {
        chooseNum.value = 5
      }  else if (newval == '/deviceList') {
        chooseNum.value = 6
      } else if (route.path == '/gameShare') {
        chooseNum.value = 7
      }  else if (route.path == '/setting') {
        chooseNum.value = 8
      }
    })
    return {
      lan,
      meauList,
      address,
      userInfo,
      levelType,
      chooseNum,
      copyWallet,
      chooseTab
    }
  }
})
</script>

<style scoped lang="scss">
.meau-cont {
  width: 100%;
  overflow: hidden;
  height: 868px;
  border-radius: 0px 40px 40px 0px;
  background: rgba(54, 63, 94, 0.08);
  .userInfo {
    width: 100%;
    display: flex;
    // cursor: pointer;
    align-items: center;
    flex-direction: column;
    // &:hover {
    //   .info {
    //     background: #FFC11C;
    //   }
    // }
    .userimg {
      width: 85px;
      height: 85px;
      margin: 40px 0 10px;
    }
    .info {
      width: 196px;
      // height: 100px;
      padding: 10px 0;
      overflow: hidden;
      text-align: center;
      border-radius: 20px;
      background: none;
      &.lan_En {
        // height: 80px;
        .level {
          font-size: 16px;
          line-height: 22px;
        }
        .time {
          font-size: 12px;
          .icon {
            width: 14px;
            height: 14px;
          }
        }
        .equipment {
          font-size: 12px;
          .icon {
            width: 14px;
            height: 14px;
          }
        }
      }
      &.active {
        background: #FFC11C;
      }
      .level {
        font-size: 20px;
        font-weight: 600;
        color: #22283E;
        line-height: 28px;
        margin-bottom: 6px;
      }
      .time {
        display: flex;
        font-size: 14px;
        align-items: center;
        justify-content: center;
        color: rgba(54, 63, 94, 0.8);
        .icon {
          width: 16px;
          height: 16px;
          margin-right: 6px;
          display: inline-block;
          background: url(../assets/time.png) no-repeat 100%/100%;
        }
      }
      .equipment {
        display: flex;
        font-size: 14px;
        margin-top: 6px;
        align-items: center;
        justify-content: center;
        color: rgba(54, 63, 94, 0.8);
        .icon {
          width: 16px;
          height: 16px;
          margin-right: 6px;
          display: inline-block;
          background: url(../assets/equipment.png) no-repeat 100%/100%;
        }
      }
    }
    .wallet {
      width: 196px;
      height: 36px;
      display: flex;
      padding: 0 12px;
      margin-top: 20px;
      align-items: center;
      border-radius: 81px;
      box-sizing: border-box;
      justify-content: space-between;
      background: rgba(255, 255, 255, 0.6);
      p {
        flex: 1;
        font-size: 14px;
        overflow: hidden;
        font-weight: 400;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: rgba(54, 63, 94, 0.8);
      }
      .icon {
        width: 16px;
        height: 16px;
        cursor: pointer;
        margin-left: 3px;
        display: inline-block;
          background: url(../assets/copy.png) no-repeat 100%/100%;
      }
    }
  }
  .noLogin {
    width: 100%;
    display: flex;
    // cursor: pointer;
    font-size: 18px;
    align-items: center;
    flex-direction: column;
    .userimg {
      width: 85px;
      height: 85px;
      margin: 40px 0 10px;
      background: url(../assets/defult.png) no-repeat 100%/100%;
    }
  }
  .meauList {
    margin-top: 30px;
    .meauli {
      width: 196px;
      height: 50px;
      display: flex;
      cursor: pointer;
      font-size: 18px;
      font-weight: 500;
      color: #666666;
      padding-left: 15px;
      margin: 0 auto 10px;
      border-radius: 20px;
      align-items: center;
      box-sizing: border-box;
      &.active {
        color: #22283E;
        background: #FFC11C;
        &:hover {
          color: #22283E;
          background: #FFC11C;
        }
      }
      &:hover {
        color: #22283E;
        background: #fff;
      }
    }
    .icon {
      width: 26px;
      height: 26px;
      margin-right: 10px;
    }
  }
}
</style>