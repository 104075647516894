<template>
  
</template>

<script>
  import { defineComponent, ref, computed } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'NFT',
    setup() {
      const store = useStore()
      let lan = computed(() => {
        return store.state.lan
      })
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss">

</style>

