import { createStore } from 'vuex'

let browserlanguage = (
  (navigator.language ? navigator.language : navigator.userLanguage) || "en"
).toLowerCase();
let language = browserlanguage.split("-")[0]

if (language == 'zh' || language == 'en' || language == 'ko' || language == 'ja' || language == 'ru' || language == 'vn') {
  language = language
} else {
  language = 'en'
}

const pair = localStorage.getItem('pair') ? JSON.parse(localStorage.getItem('pair')) : undefined

export default createStore({
  state: {
    lan: localStorage.getItem('lan') || language || 'en',
    account: pair ? pair.address : undefined,
    LocalData: {},
    onlineStatus: ''
  },
  mutations: {
    changeLan(state, data) {
      state.lan = data
    },
    setAccount(state, data) {
      state.account = data
    },
    setLocalData(state, data) {
      state.LocalData = data
    },
    setOnlineStaus(state, data) {
      state.onlineStatus = data.state
    }
  }
})