export default {
  public: {
    copysuccess: '복사성공',
    days: '일',
    minimize: '최소화',
    closure: '닫기',
    linkFail: '연결이 끊긴 서버',
    linkSuccess: '연결 준비 완료(보안 연결)',
    inputPwd: '비밀번호입력',
    pwdError: '비밀번호오류',
    seedError: '개인키오류',
    unlock: '잠금해제',
    inputSeed: '붙여넣기또는개인키입력',
    walletbalance: '지갑잔액',
    balance: '잔액',
    equivalent: '동등한',
    price: '가격',
    dollar: 'USD',
    add: '추가',
    edit: '편집',
    save: '보관',
    cancal: '취소',
    hour: '시간',
    min: '분',
    second: '초',
    codeError: '코드오류',
    updateError: '업데이트오류',
    customError: '맞춤설정오류',
    tokenError: '생성오류,페이지 버튼을 다시 클릭해주세요',
    netError: '서버연결오류,잠시만기다려주세요',
    
    copy: '복사',
    introDBC: 'DBC소개',
    loginErr: '지갑로그인',
    msgErrInfo1: '초기화오류',
    msgErrInfo1000: '초기화오류',
    msgErrInfo1001: '초기화완료',
    msgErrInfo1002: '매개변수오류',
    msgErrInfo1003: '스트리밍시작오류',
    msgErrInfo1004: '스트리밍중',
    msgErrInfo1005: 'FD에러',
    msgErrInfo1006: '서버연결오류',
    msgErrInfo1007: 'license에러혹은만료',
    msgErrInfo1008: '내부오류',
    msgErrInfo1009: '인증오류',
    msgErrInfo1010: '인증시간초과',
    msgErrInfo1011: '영상스트림생성오류',
    msgErrInfo1012: '렌더링에러',
    msgErrInfo1013: '연결오류',
    msgErrInfo1014: '연결신호시간초과',
    msgErrInfo1015: '홀펀치시간초과',
    msgErrInfo1016: '전송오류',
    msgErrInfo1017: '모니터없거나 가상모니터 생성오류',
    msgErrInfo1018: '설비오프라인',

    comfirm: '확인하다'
  },
  meau: {
    nav1: '원격지원',
    nav2: '설정',
    nav3: '기기리스트',
    nav4: '내지갑',
    login: '로그인',
    user1: '일반유저',
    user2: '크라운NFT(전문버전)',
    user3: '크라운NFT(기업버전)',
    time: '남은유효기간',
    devices: '기기수량',
  },
  wallet: {
    newWallet: '새지갑',
    openWallet: '지갑오픈',
    createWallet: '지갑만들기',
    tips1: '비밀번호는 꼭 기억하세요! 지갑 잠금해제하려면 이 비밀번호와 개인 키 파일이 필요합니다.',
    tips2: '개인 키 파일을 저장하고 파일을 안전한 곳에 보관하세요. 예를 들어, 수동으로 복사하여 금고에 보관하세요.',
    seed: '개인키',
    seed1: '개인키',
    keystore: 'Keystore파일',
    tips3: '잃어버리지 마세요! 분실하면 복구할 수 없습니다.',
    tips4: "공유하지 마세요! Wechat、QQ、Facebook、Line、KakaoTalk、WhatsApp및 기타 소셜 네트워크 소프트웨어에서 서로 전송하지 마세요. 악성 피싱 사이트에서 이 파일을 사용하면 돈을 도난당할 수 있습니다.",
    tips5: '반드시 백업하세요! 언젠가 수백만 달러의 가치가 있는 것처럼.',
    create: '만들기',
    finish: '완성',
    selectFile: '지갑파일선택',
    instructions: '지갑-사용설명',
    desc1: '지갑에 로그인후, 더 많은 고급 기능을 지원할 수 있는 전문버전 크라운 NFT를 구매할 수 있습니다.',
    details: '상세내용',
    desc2: '지갑에 로그인 후 DeepBrainChain Coin및 DeepLink Coin을 구매할 수 있습니다.',
    desc3: '지갑에 로그인 후 게임 및 기타 서비스를 구매할 수 있습니다',
    desc4: "지갑에 로그인 후 다른 플레이어에게 로컬 게임을 공유하여 DeepBrainChain Coin및 DeepLink Coin'을 획득할 수 있습니다.",
  },
  home: {
    tip: '개인키 정보를 안전하게 보관하세요. 개인키를 분실하면 디지털 자산을 복구할 수 없습니다!',
    address: '지갑주소',
    transfer: '이체',
    buy: '매수',
    exchange: '교환',
    switch: '지갑닫기',
    hash: '이체기록 Hash값',
    source: '소스주소',
    target: '목표주소',
    result: '결과',
    number: '수량',

    currency: '통화',
    password: '비밀번호',
    msg: '비워둘 수 없습니다',
    msg1: '숫자 유형이어야 합니다',
    msg2: '전송 성공',
    msg3: '전송 실패',
  },
  view: {
    err: '확인 코드 길이는 6~10자의 문자열입니다.',
    delect: '삭제성공',
    delecterr: '삭제오류',
    title1: '원격 제어 허용',
    localcode: '장치 ID',
    localvercode: '비밀번호',
    updatevercode: '새로 고침',
    customvercode: '사용자 정의',
    join: '커뮤니티에 가입하여 DeepLink Coin보상 받기',
    title2: '원격 장치 제어',
    remotecode: '파트너 장치 ID',
    remotevercode: '비밀번호',
    singlescreen: '기본 화면',
    expansionscreen: '확장된 화면',
    remotenow: '연결',
    error1: '정확한 9자리 식별코드를 입력하세요.',
    error2: '정확한 6자리 인증코드를 입력하세요.',
    error3: '실패 횟수가 너무 많습니다. 10분 후에 다시 시도해 주세요.',
  },
  device: {
    devList: '기기리스트',
    adddev: '기기추가',
    intcafe: '클라우드PC방',
    remaining: '남은',
    renew: '임대연기',
    currentdev: '현재기기',
    none: '무',
    details: '주문세부정보',
    identifier: '식별코드',
    vercode: '인증코드',
    macsystem: '기기시스템',
    remark: '비고',
    countdown: '카운트다운',
    local: '위치',
    rentalTime: '총임대시간',
    devdetails: '기기주문상세정보',
    index: '순번',
    LeaseStartTime: '임대시작시간',
    rental: '임대시간',
    useTime: '실제사용시간',
    rentPaid: '임대료지불',
    refund: '임대료환불'
  },
  setting: {
    name1: '일반 설정',
    name2: '보안 설정',
    name3: '기타 설정',
    rule: {
      title1: '시작 및 업데이트',
      label1: '윈도우 시작시 자동 실행',
      label2: '서비스(닫은 후에도 여전히 원격)',
      label3: '자동 업데이트',
      title2: '단축키 설정',
      label4: '전체/창',
      label5: '최소화',
      label6: '화면 전환',
      title3: '언어',
      label7: '기기언어',
      error: '단축키충돌,재설정중'
    },
    safe: {
      title1: '자동 잠금 화면',
      label1: '원격으로 연결되면 화면 잠금',
      label2: '원격 종료 후 화면 잠금',
      label3: '화면보호기 꺼진 후 화면 잠금',
      title2: '비밀번호 업데이트',
      label4: '영구 암호',
      label5: '원격 지원 후',
      label6: '업데이트 설정'
    },
    other: {
      title1: '음소거 제어 장치',
      title2: '네트워크 속도 설정',
      title3: '해상도 설정',
      title4: '프레임 설정(FPS)',
      title5: '가상 디스플레이',
      label1: '호스트 설정 값 사용',
      label2: '클라이언트 설정 값 사용'
    }
  }
}